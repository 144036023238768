import { gql } from '@apollo/client';
import { COMPONENT_FRAGMENTS } from './fragments';

const getDefaultTaggedContentFilter = () => `{
          AND:[
            {contentfulMetadata: {
              tags_exists: true
              tags: {
                id_contains_some: $groupId
              }
            }},
            {contentfulMetadata: {
              tags_exists: true
              tags: {
                id_contains_some: $nativeTagIds
              }
            }},
          ]
        }`;

const createCategoriesFilterString = () => {
  let filterString = '';
  for (let i = 0; i < 5; i += 1) {
    filterString = `${filterString} {contentfulMetadata: {
              tags_exists: true
              tags: {
                id_contains_some: $category${i}
              }
            }},`;
  }
  return filterString;
};
const getFilteredTaggedContentFilter = () => `{
          AND:[
            {contentfulMetadata: {
              tags_exists: true
              tags: {
                id_contains_some: $groupId
              }
            }},
            ${createCategoriesFilterString()}
          ]
        }`;

const getTaggedItemsString = () => `{
          total
        items {
          contentfulMetadata {
            tags {
              id
              name
            }
          }
          slug
          navigationTag {
            title
            slug
          }
          meta {
            image {
              ...image
            }
          }
          hero {
            title
            foregroundImage {
              ...image
            }
          }
        }
      }`;

const initialTaggedContentString = () => `(
        where: ${getDefaultTaggedContentFilter()}
        limit: $limit
        skip: $skip
      )${getTaggedItemsString()}`;

const filteredTaggedContentString = () => `(
        where: ${getFilteredTaggedContentFilter()}
        limit: $limit
        skip: $skip
      )${getTaggedItemsString()}`;

export const GET_INITIAL_TAGGED_CONTENT = ({ locale = '' }) => gql`
    query GetInitialContent(
        $nativeTagIds: [String],
        $groupId: [String],
        $skip: Int,
        $limit: Int) {
      pageDetailCollection: pageDetail${locale}Collection${initialTaggedContentString()}
      pageDetailGlobalCollection: pageDetailGlobal${locale}Collection${initialTaggedContentString()}
    }
    ${COMPONENT_FRAGMENTS.IMAGE}
  `;

export const GET_FILTERED_TAGGED_CONTENT = ({ locale = '' }) => gql`
    query GetTaggedContent($category0: [String], $category1: [String], $category2: [String], $category3: [String], $category4: [String],
        $groupId: [String],
        $skip: Int,
        $limit: Int) {
      pageDetailCollection: pageDetail${locale}Collection${filteredTaggedContentString()}
      pageDetailGlobalCollection: pageDetailGlobal${locale}Collection${filteredTaggedContentString()}
    }
    ${COMPONENT_FRAGMENTS.IMAGE}
  `;

import styled from 'styled-components';

import { Eyebrow, Headline, ArrowCta as CtaComponent } from 'components/atoms';
import { getThemeColor, THEME_SELECTOR_TYPES } from 'utils/get-theme-color';
import { animations, ratioPercentage, cssFragments } from 'styles/variables';
import { columnsGutter, container } from 'styles/grid';
import { maxWidth, minWidth } from 'styles/media-queries';
import { Markdown } from 'components/molecules';

import { multiple } from 'components/atoms/arrow-cta/ArrowCta.styled';
import { black, white } from 'styles/colors';
import { spacer16, spacer24, spacer80, spacer64 } from 'styles/spacers';
import {
  ACTIVATE_ANIMATION_CLASS,
  fadeContentSlideUp,
} from 'styles/animations';

// Styled components
export const FeatureCard = styled.section.attrs(props => ({
  id: props.id,
}))`
  ${({ theme }) => !theme.fullWidth && container}

  ${({ theme }) =>
    theme.themeSelectorType === THEME_SELECTOR_TYPES.GET_STARTED &&
    `
    ${fadeContentSlideUp.setup}
    &.${ACTIVATE_ANIMATION_CLASS} {
      ${fadeContentSlideUp.play}
      animation-delay: 700ms;
    }
  `}

  color: ${({ theme }) => getThemeColor(theme.themeKey)};
`;

export const Container = styled.div`
  padding: ${spacer24} ${spacer16};
  border-radius: 1rem;

  ${({ theme }) =>
    theme.backgroundColor
      ? `background-color: ${theme.backgroundColor};`
      : `background-color: ${black};`}


  ${({ theme }) =>
    theme.themeSelectorType === THEME_SELECTOR_TYPES.GET_STARTED &&
    `
    padding: 4.8rem 1.6rem;
  `}

  ${minWidth.md`
     ${({ theme }) =>
       theme.themeSelectorType === THEME_SELECTOR_TYPES.HERO &&
       ` padding: 2.4rem;
          border-radius: 1rem;
      `}
  `}

  ${minWidth.lg`
    ${columnsGutter}
    padding: ${spacer80} ${spacer64};
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    ${({ theme }) =>
      theme.themeSelectorType === THEME_SELECTOR_TYPES.AD_FORMAT &&
      `
      padding: 0;
      padding-bottom: 8rem;
  `}

    ${({ theme }) =>
      theme.hasImage &&
      `
      align-items: center;
    `}
  `}
`;

export const Image = styled.div.attrs(props => ({
  role: props.alt ? 'img' : 'presentation',
  'aria-label': props.alt,
}))`
  display: block;
  margin-bottom: 4rem;
  overflow: hidden;
  position: relative;
  ${({ theme }) =>
    theme.themeSelectorType === THEME_SELECTOR_TYPES.AD_FORMAT
      ? `padding-top: ${ratioPercentage.oneOne}%;`
      : `padding-top: ${ratioPercentage.fourThree}%;`}

  &::before {
    ${({ theme }) =>
      theme.themeSelectorType === THEME_SELECTOR_TYPES.AD_FORMAT &&
      cssFragments.backgroundScaledImageAdFormats}

    ${({ theme }) =>
      theme.themeSelectorType === THEME_SELECTOR_TYPES.GET_STARTED &&
      cssFragments.backgroundScaledImageGetStarted}

    ${({ theme }) =>
      theme.themeSelectorType === THEME_SELECTOR_TYPES.AD_FORMAT ||
      theme.themeSelectorType === THEME_SELECTOR_TYPES.GET_STARTED
        ? `background-size: contain;`
        : `background-size: cover;`}

    ${({ theme }) =>
      theme.themeSelectorType !== THEME_SELECTOR_TYPES.AD_FORMAT &&
      theme.themeSelectorType !== THEME_SELECTOR_TYPES.GET_STARTED &&
      cssFragments.backgroundScaledImageGetStarted}

    background-position: 50% center;
    background-repeat: no-repeat;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform ${animations.defaultTransition};
    will-change: transform;
  }

  &.hovering {
    &::before {
      transform: scale(1.1);
    }
  }

  ${minWidth.lg`
    grid-column: span 5;
    margin-bottom: 0;

    ${({ theme }) =>
      theme.themeSelectorType === THEME_SELECTOR_TYPES.AD_FORMAT &&
      `
      grid-column: span 6;
    `}

    ${({ theme }) =>
      theme.imageRight &&
      `
      grid-column: 7 / span 6;
      height: 100%;
    `}
  `}
`;

export const Content = styled.div`
  ${maxWidth.sm`
    ${({ theme }) =>
      theme.hasImage &&
      `
      padding-bottom: 4.8rem;
    `}
  `}


  ${minWidth.md`
    ${({ theme }) =>
      theme.hasImage &&
      `
      padding-bottom: 2.4rem;
    `}
  `}
  ${minWidth.lg`
    ${columnsGutter}

    display: grid;
    grid-column: span 12;
    grid-template-columns: repeat(12, 1fr);

    ${({ theme }) =>
      theme.hasImage &&
      `
      display: flex;
      flex-direction: column;
      grid-column: 7 / span 6;
    `}

    ${({ theme }) =>
      theme.imageRight &&
      `
      grid-column: 1 / span 6;
      grid-row: 1;
    `}
  `}
`;

export const HeadlineContainer = styled.div`
  ${minWidth.lg`
    grid-column: span 6;
  `}
`;

export const ColumnList = styled(props => <Markdown {...props} />)`
  margin: 2.4rem 0 5rem 0;
`;

export const TitleEyebrow = styled(Eyebrow)`
  margin-bottom: 0.8rem;

  ${minWidth.lg`
    margin-bottom: 2.4rem;
  `}
`;

export const TitleNumberEyebrow = styled(Eyebrow)`
  margin-bottom: 1.6rem;
  background-color: ${white};
  color: ${black};
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 50%;
  font-weight: 1000;

  ${minWidth.lg`
    width: 4.6rem;
    height: 4.6rem;
  `}
`;

export const Title = styled(Headline)`
  color: ${({ theme }) => theme.titleColor || getThemeColor(theme.themeKey)};
`;

export const DescriptionContainer = styled.div`
  margin-top: ${spacer16};

  ${minWidth.lg`
    grid-column: 8 / span 5;
  `}

  li {
    font-weight: bold;
  }
`;

export const CtasContainer = styled.div`
  margin-top: 1.6rem;
  display: flex;
  gap: 1.2rem;

  ${minWidth.lg`
    margin-top: 2.4rem;
  `}
`;

export const Cta = styled(CtaComponent)`
  ${multiple};
  margin-right: 1.2rem;
`;

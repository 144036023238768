import styled from 'styled-components';

import {
  Cta as CtaComponent,
  Eyebrow as EyebrowComponent,
} from 'components/atoms';
import { columnsGutter, container } from 'styles/grid';
import { maxWidth, minWidth } from 'styles/media-queries';
import { solidWhite200 } from '@spotify-internal/encore-web';

export const Container = styled.div`
  ${({ theme }) => !theme.fullWidth && container}
  ${({ theme }) => !theme.fullWidth && `padding: 1rem 2rem 5rem 2rem;`}
  color: ${solidWhite200};
  background-color: ${({ theme }) =>
    theme.backgroundColor ? theme.backgroundColor : 'transparent'};

  ${minWidth.lg`
  ${({ theme }) =>
    theme.backgroundColor ||
    (theme.fullWidth &&
      `
      max-width: 10000px;
    `)}
  `}
`;

export const HeaderContainer = styled.div`
  ${minWidth.lg`
    ${columnsGutter}

    ${({ theme }) =>
      !theme.hasText &&
      `
      display: none;
    `}


    ${({ theme }) =>
      !theme.hasCta &&
      `
      display: grid;
      grid-template-columns: repeat(12, 1fr);
    `}
  `}
`;

export const TitleContainer = styled.div`
  ${minWidth.lg`
    ${({ theme }) => !theme.hasCta && `grid-column: 1 / span 6;`}
  `}
`;

export const Eyebrow = styled(EyebrowComponent)``;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3rem 0rem;

  ${minWidth.lg`
    ${({ theme }) => !theme.hasCta && `grid-column: 8 / span 5;`}
  `}
`;

export const Cta = styled(CtaComponent)`
  width: fit-content;
  margin-top: 3rem;
`;

export const ModuleContainer = styled.div`
  margin-top: 6rem;

  ${minWidth.lg`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column: 1 / span 12;
  `}
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow: hidden;
  align-content: center;
  justify-content: center;
  padding: 1rem;

  div {
    img {
      object-fit: cover;
      background-position: center top;
      background-repeat: no-repeat;
      height: 580px;
    }
  }

  ${maxWidth.sm`
    div {
    img {
      object-fit: cover;
      background-position: center top;
      background-repeat: no-repeat;
      width: 30rem;
      height: 30rem;
    }
  }
  `}

  .carousel-card {
    width: 100%;
    height: 100%;
    aspect-ratio: 3 / 4;
  }

  ${minWidth.lg`
    grid-column: 1 / span 6;
    align-items: center;
  `}
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  overflow: hidden;
  padding: 1rem;

  div {
    img {
      object-fit: contain;
      background-position: center top;
      background-repeat: no-repeat;
      width: 50rem;
      height: 50rem;
    }
  }

  .list-item {
    position: relative;
    width: 100%;
    margin-left: unset;
  }

  ${maxWidth.sm`
    div {
    img {
      object-fit: contain;
      background-position: center top;
      background-repeat: no-repeat;
      width: 30rem;
      height: 30rem;
    }
  }
  `}
  ${minWidth.lg`
    grid-column: 7 / span 6;
    align-items: center;
  `}
`;

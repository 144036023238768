import styled from 'styled-components';

import { Headline, Icon, Paragraph } from 'components/atoms';
import { solidBlack0 } from '@spotify-internal/encore-web';
import { spacer2 } from 'styles/spacers';
import { STAT_CARD_THEMES } from 'utils/get-theme-selector';
import { white } from 'styles/colors';
import { maxWidth } from 'styles/media-queries';

// Styled components
export const StatCard = styled.div`
  ${props =>
    props.theme.themeType === STAT_CARD_THEMES.IMAGE
      ? `background-image: url(${props.url}); background-size: cover;`
      : `background-color: ${
          props.theme.backgroundColor
            ? props.theme.backgroundColor
            : solidBlack0
        };`}

  ${props =>
    props.theme.themeType === STAT_CARD_THEMES.TEXT_WITH_BOLD_STAT &&
    props.theme.hasImage
      ? `background-image: linear-gradient(rgba(0,0,0,.3), rgba(0,0,0,.3)), url(${props.url}); background-size: cover;`
      : `background-color: ${
          props.theme.backgroundColor
            ? props.theme.backgroundColor
            : solidBlack0
        };`}

  color: ${props => props.theme.textColor || white};
  position: relative;
  border-radius: 10px;
  padding: 16px;
  overflow: hidden;
  aspect-ratio: 4 / 5;
`;

export const TitleContainer = styled.div`
  padding: ${spacer2};
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  p {
    font-size: 1.8rem;
  }
`;

export const CaptionContainer = styled.div`
  background-color: ${props =>
    props.theme.themeType === STAT_CARD_THEMES.TEXT_WITH_BOLD_STAT
      ? `transparent`
      : props.theme.backgroundColor || solidBlack0};
  padding: 24px 16px;
  position: absolute;
  bottom: 0;
  left: 0;

  p {
    position: unset;
  }
`;

export const CaptionTitle = styled(Headline)`
  padding-left: 0.5rem;
`;

export const Caption = styled(Paragraph)`
  position: absolute;
  bottom: 20px;
  padding: ${spacer2} 1.25rem ${spacer2} 0.5rem;
  font-size: 1.8rem;
`;

export const Logo = styled(Icon)`
  svg {
    width: 4.8rem;
    height: 4.8rem;

    ${maxWidth.sm`
      width: 3.3rem;
      height: 3.3rem;
      margin-bottom: 0.5rem;
    `}
  }
  path {
    fill: ${solidBlack0};
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { ICONS } from 'constants/icons';
import { Icon } from 'components/atoms';
import * as Styled from './Button.styled';

/**
 * Button component
 * @param {String} type Button type
 * @param {Function} onClick on click handler function
 * @param {String} className Class to override current styles
 * @param {String} text text to place into tag buttons
 */
const Button = ({
  type = 'roundClose',
  onClick = () => {},
  className = '',
  text,
}) => {
  switch (type) {
    case 'roundClose':
      return (
        <Styled.RoundClose onClick={onClick}>
          <Icon name={ICONS.CLOSE_MARK} />
        </Styled.RoundClose>
      );
    case 'roundLeftArrow':
      return (
        <Styled.RoundArrow onClick={onClick}>
          <Icon name={ICONS.ARROW_LEFT_NO_CONTAINER} />
        </Styled.RoundArrow>
      );
    case 'roundRightArrow':
      return (
        <Styled.RoundArrow onClick={onClick} className={className}>
          <Icon name={ICONS.ARROW_RIGHT_NO_CONTAINER} />
        </Styled.RoundArrow>
      );
    case 'articleTag':
      return (
        <Styled.ArticleTag className={className} onClick={onClick}>
          {text}
        </Styled.ArticleTag>
      );
    case 'filter':
      return (
        <Styled.FilterButton onClick={onClick}>
          {text}
          <Icon name={ICONS.FILTER} />
        </Styled.FilterButton>
      );
    case 'filterName':
      return (
        <Styled.FilterButton onClick={onClick}>
          {text}
          <Icon name={ICONS.CLOSE_MARK} />
        </Styled.FilterButton>
      );
    case 'secondary':
      return (
        <Styled.SecondaryButton onClick={onClick}>
          {text}
        </Styled.SecondaryButton>
      );
    default:
      return null;
  }
};

Button.propTypes = {
  /**
   * Button type
   */
  type: PropTypes.string.isRequired,
  /**
   * OnClick Handler
   */
  onClick: PropTypes.func,
  /**
   * Class to override current styles
   */
  className: PropTypes.string,
};

export default Button;

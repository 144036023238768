import React from 'react';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';

import { getLinkProps } from 'utils/get-link-props';
import { useTextFit } from 'utils/use-text-fit';
import {
  eventTrack,
  MULTI_CTA_CLICK,
  MODULES_MULTI_CTA,
  AUTO_SCROLL_FROM_COMPONENTS,
} from 'utils/google-tag-manager';
import getMultiCtaAction from 'utils/google-tag-manager/getMultiCtaAction';

import { useImageSupport } from 'utils/use-image-support';
import { ForegroundImageType } from 'constants/common-proptypes';
import { AutoScroll } from 'components/atoms/auto-scroll';
import * as Styled from './HeroBubble.styled';
/**
 * Hero Component
 * @param {object} props
 * @param {object} props.data - Page data
 * @returns {ReactComponent}
 */

export interface CTAItemProps {
  overrideFunctionality: string;
  title: string;
  type: string;
  url: string;
}
export interface HeroBubbleProps {
  /**
   * Page data
   */
  data: {
    heroBubble: {
      title: string;
      description: string;
      subheader?: string;
      ctasCollection?: {
        items: CTAItemProps[];
      };
      foregroundImage?: ForegroundImageType;
      imageType?: boolean;
    };
  };
  scrollRef: React.MutableRefObject<any>;
}

const HeroBubble = ({ data, scrollRef }: HeroBubbleProps) => {
  const { heroBubble } = data;
  const {
    title,
    subheader,
    description,
    ctasCollection,
    foregroundImage,
    imageType,
  } = heroBubble;

  const ctaItems = get(ctasCollection, 'items', []);
  const { queryUrl, fileExt } = useImageSupport();

  // @ts-ignore
  const { style, textFitRef, containerToFitRef } = useTextFit({
    // @ts-ignore
    paddingBreakpoints: { sm: 0, lg: 88 },
  });

  return (
    <Styled.Root>
      <Styled.Container>
        <Styled.Content ref={containerToFitRef} imageType={imageType}>
          {/* @ts-ignore */}
          <Styled.TitleIntro ref={textFitRef} style={style}>
            {title}
          </Styled.TitleIntro>
          {subheader && <Styled.SubHeader>{subheader}</Styled.SubHeader>}
          <Styled.Description>{description}</Styled.Description>
          {ctaItems.map((ctaItem: CTAItemProps, indexCta: number) => {
            const {
              title: ctaTitle,
              url,
              overrideFunctionality,
              type,
            } = ctaItem;
            const { href, asLink } = getLinkProps(url);

            const onCtaClick = (event: React.MouseEvent<HTMLElement>) => {
              const { isModalFormOpen } = get(event, 'data', {});
              const actionText = getMultiCtaAction(
                { overrideFunctionality, url },
                isModalFormOpen,
              );

              eventTrack(MULTI_CTA_CLICK, {
                event,
                module: MODULES_MULTI_CTA.heroDynamic,
                actionText,
                headerText: ctaTitle,
              });
            };

            return (
              // @ts-ignore
              <Styled.Cta
                key={kebabCase(`${ctaTitle}-${indexCta}`)}
                overrideFunctionality={overrideFunctionality}
                href={href}
                asLink={asLink}
                isDarkTheme={false}
                onClick={onCtaClick}
                type={type}
              >
                {ctaTitle}
              </Styled.Cta>
            );
          })}
        </Styled.Content>
        {foregroundImage && fileExt && (
          <Styled.HeroImage imageType={imageType}>
            {imageType ? (
              <Styled.MobileChromeImage
                data-src={foregroundImage[queryUrl]}
                aria-label={foregroundImage.description || ''}
              />
            ) : (
              <Styled.DesktopChromeImage
                // @ts-ignore
                src={foregroundImage[queryUrl]}
                alt={foregroundImage.description || ''}
              />
            )}
          </Styled.HeroImage>
        )}
        <Styled.DownArrowWrapper>
          <AutoScroll
            scrollRef={scrollRef}
            scrollFrom={AUTO_SCROLL_FROM_COMPONENTS.HERO_BUBBLE}
          />
        </Styled.DownArrowWrapper>
      </Styled.Container>
    </Styled.Root>
  );
};

export default HeroBubble;

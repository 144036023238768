import styled from 'styled-components';

import {
  introTypographyXsToMd,
  introTypographyLgToXl,
} from 'components/molecules/intro/Intro.styled';
import { minWidth } from 'styles/media-queries';
import { solidBlack0, solidWhite200 } from '@spotify-internal/encore-web';
import { fontWeights } from 'styles/variables';

export const SuccessState = styled.div`
  background-color: ${({ theme }) =>
    theme.modifier === 'footer' ? `transparent` : solidBlack0};
  color: ${solidWhite200};
`;

export const Copy = styled.p`
  margin-bottom: 3.2rem;

  &:last-child {
    margin-bottom: 0;
  }
  ${minWidth.lg`
    margin-bottom: 4rem;
  `}

  ${({ theme }) =>
    theme.modifier === 'footer'
      ? `
        font-weight: ${fontWeights.normal_p};
      `
      : `
      ${introTypographyXsToMd}

      ${minWidth.lg`
        ${introTypographyLgToXl}

        margin-bottom: 4rem;
      `}
  `}
`;

import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes, { any } from 'prop-types';

import get from 'lodash/get';

import { ICONS } from 'constants/icons';
import { getLinkProps } from 'utils/get-link-props';
import { MODULES_MULTI_CTA } from 'utils/google-tag-manager';

import { useComponentSize } from 'utils/use-component-size';
import classNames from 'classnames';
import mParticle from '@mparticle/web-sdk';
import * as Styled from './Navigation.styled';

/**
 * List with the type of components for SubNav
 */
const COMPONENTS = {
  DROPDOWN: 'Dropdown',
};

/**
 * Creates a DropdownMenu instance for SubNav
 * @param {object} item Navigation Item data
 * @param {string} locale Current Locale
 * @param {function} onClick On Click handler for links
 * @param {number} listHeight - The list height
 * @param level
 * @param {function} onLayout - The layout change listener
 * @param {boolean} isMobile - boolean to determine the layout of the submenu
 * @param {boolean} isCurrentPath - determines the color/styling of the text link
 * @param className
 */
const Dropdown = ({
  item,
  locale,
  onClick,
  listHeight,
  level,
  onLayout = () => {},
  isMobile,
  isCurrentPath,
  className,
}) => {
  const [subNavFocused, setSubNavFocused] = useState(false);
  const navUrl =
    get(item, 'rootLink.slug', null) || get(item, 'rootLink.url', '');
  const { href, asLink } = getLinkProps(navUrl, locale);
  const containerRef = useRef(null);
  const { height } = useComponentSize(containerRef);

  const onMenuClick = useCallback(() => {
    mParticle.logEvent('action', mParticle.EventType.Other, {
      action_text: 'menu click',
      action_location: MODULES_MULTI_CTA.dropdown,
    });
  }, [href, asLink]);

  useEffect(() => {
    if (height !== 0 && typeof height === 'number') {
      onLayout(height);
    }
  }, [height]);

  return (
    <Styled.NavTextLinkContainer
      subNavFocused={subNavFocused}
      listHeight={listHeight}
      className={classNames(level, `${className}-dropdown`)}
      level={level}
    >
      {level === 'lower' ? (
        <Styled.LowerSubNavContainer
          subNavFocused={subNavFocused}
          setSubNavFocused={setSubNavFocused}
          level={level}
          isMobile={isMobile}
        >
          <Styled.NavTextLinkDrop onClick={onMenuClick} className={level}>
            {item.rootLink.title}
            <Styled.Caret name={ICONS.CARET_DOWN} className="nav-caret" />
          </Styled.NavTextLinkDrop>
          <Styled.LowerSubNav
            className={subNavFocused && 'open'}
            isMobile={isMobile}
            level={level}
          >
            <ul ref={containerRef}>
              {item.subLinksCollection.items.map(({ sys, title, url }) => {
                const { href: subHref, asLink: subAsLink } = getLinkProps(url);

                return (
                  <Styled.LowerSubNavItem key={sys.id} level={level}>
                    <Styled.SubNavLink
                      onClick={event => {
                        mParticle.logEvent(
                          'action',
                          mParticle.EventType.Other,
                          {
                            action_text: 'sub navigation link click',
                            action_location: MODULES_MULTI_CTA.dropdown,
                          },
                        );
                        onClick(event);
                      }}
                      href={subHref}
                      asLink={subAsLink}
                    >
                      {title}
                    </Styled.SubNavLink>
                  </Styled.LowerSubNavItem>
                );
              })}
            </ul>
          </Styled.LowerSubNav>
        </Styled.LowerSubNavContainer>
      ) : (
        <Styled.SubNavContainer
          subNavFocused={subNavFocused}
          setSubNavFocused={setSubNavFocused}
          isMobile={isMobile}
        >
          <Styled.NavTextLinkDrop
            onClick={onMenuClick}
            isCurrentPath={isCurrentPath}
          >
            {item.rootLink.title}
            <Styled.Caret
              name={ICONS.CARET_DOWN_SMALL}
              className={isMobile && 'mobile-nav-caret'}
            />
          </Styled.NavTextLinkDrop>
          <Styled.SubNav
            className={subNavFocused && 'open'}
            isMobile={isMobile}
          >
            {isMobile && (
              <Styled.Caret name={ICONS.CARET_DOWN} className="subnav-caret" />
            )}
            <ul ref={containerRef}>
              {item.subLinksCollection.items.map(({ sys, title, url }) => {
                const { href: subHref, asLink: subAsLink } = getLinkProps(url);

                return (
                  <Styled.SubNavItem
                    key={sys.id}
                    className={classNames(level, `${className}-dropdown`)}
                  >
                    <Styled.SubNavLink
                      onClick={event => {
                        mParticle.logEvent(
                          'action',
                          mParticle.EventType.Other,
                          {
                            action_text: 'sub navigation link click',
                            action_location: MODULES_MULTI_CTA.dropdown,
                          },
                        );
                        onClick(event);
                      }}
                      href={subHref}
                      asLink={subAsLink}
                    >
                      {title}
                    </Styled.SubNavLink>
                  </Styled.SubNavItem>
                );
              })}
            </ul>
          </Styled.SubNav>
        </Styled.SubNavContainer>
      )}
    </Styled.NavTextLinkContainer>
  );
};

/**
 * Creates a regular navigation item instance with no dropdown for subnav
 * @param {object} item Navigation Item data
 * @param {string} locale Current Locale
 * @param {function} onClick On Click handler for links
 * @param {string} level
 * @param {boolean} isCurrentPath
 * @param isBackButton
 * @param lowerNavgationSlug
 * @returns {ReactElement}
 */
const NavItem = ({
  item,
  locale,
  onClick,
  level,
  isCurrentPath,
  isBackButton,
  lowerNavigationSlug,
  isHomepage,
  className,
}) => {
  const landingLinks =
    typeof lowerNavigationSlug === 'string' &&
    lowerNavigationSlug.startsWith('landing');
  const asLink =
    level === 'lower' && !isHomepage && !landingLinks
      ? `/${locale}/${lowerNavigationSlug}/${item.slug}`
      : `/${locale}/${item.slug}`;
  const href =
    level === 'lower' && !isHomepage && !landingLinks
      ? `/[locale]/[slug]/[tag]`
      : '/[locale]/[tag]';
  const onMenuClick = useCallback(
    event => {
      mParticle.logEvent('action', mParticle.EventType.Other, {
        action_text: 'navigation link click',
        action_location: MODULES_MULTI_CTA.navigationBar,
      });
      onClick(event);
    },
    [asLink],
  );

  return (
    <Styled.NavTextLinkContainer
      className={classNames(level, className)}
      isBackButton={isBackButton}
    >
      <Styled.NavTextLink
        onClick={onMenuClick}
        href={href}
        asLink={asLink}
        isCurrentPath={isCurrentPath}
        isBackButton={isBackButton}
        className={classNames(level, className)}
      >
        {isBackButton ? `Back to All ${item.title}` : item.title}
      </Styled.NavTextLink>
    </Styled.NavTextLinkContainer>
  );
};

/**
 * Creates a dropdown or a Navigation Link depending on the data.
 * @param {object} item Navigation Item data
 * @param {string} locale Current Locale
 * @param {function} onClick On Click handler for links
 * @param {number} listHeight - The list height
 * @param {string} level determines the styling and type of sub nav dropdown
 * @param {function} onLayout - The layout change listener
 * @param {boolean} isMobile used to determine which dropdown type to use
 * @param {boolean} isCurrentPath used for nav link styling
 * @param {boolean} isBackButton used for nav link styling
 * @param lowerNavigationSlug
 * @param style
 * @param isHomepage
 * @returns {ReactElement}
 */
export const SubNav = ({
  item,
  locale,
  onClick,
  listHeight,
  level,
  onLayout = () => {},
  isMobile,
  isCurrentPath,
  isBackButton,
  lowerNavigationSlug,
  isHomepage,
  className,
}) => {
  const { type } = item;

  switch (type) {
    case COMPONENTS.DROPDOWN:
      return (
        <Dropdown
          item={item}
          locale={locale}
          onClick={onClick}
          listHeight={listHeight}
          onLayout={onLayout}
          level={level}
          isMobile={isMobile}
          isCurrentPath={isCurrentPath}
          className={className}
        />
      );
    default:
      return (
        <NavItem
          item={item}
          locale={locale}
          onClick={onClick}
          level={level}
          isCurrentPath={isCurrentPath}
          isBackButton={isBackButton}
          lowerNavigationSlug={lowerNavigationSlug}
          isHomepage={isHomepage}
          className={className}
        />
      );
  }
};

SubNav.propTypes = {
  /**
   * Navigation Item data
   */
  item: PropTypes.objectOf(any).isRequired,
  /**
   * Current Locale
   */
  locale: PropTypes.string.isRequired,
  /**
   * On Click handler for links
   */
  onClick: PropTypes.func.isRequired,
};

import styled from 'styled-components';
import { gray20 } from '@spotify-internal/encore-web';
import { Headline } from 'components/atoms';
import { minWidth } from 'styles/media-queries';
import { animations, fontWeights } from 'styles/variables';
import { darkGray, white } from 'styles/colors';

export const Container = styled.div`
  background-color: ${props =>
    props.theme.showBackgroundColor ? darkGray : 'transparent'};
  color: ${props => props.textColor || white};
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  position: relative;
  padding: 2rem;
  border-radius: 0.5rem;
  transition: tranform ${animations.defaultTransition};

  &:hover {
    transform: scale(1.02);
    background-color: ${gray20};
    cursor: pointer;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 12;

  ${minWidth.lg`
    grid-column: 2 / span 10;
    margin-left: 2rem;
  `}
`;

export const Title = styled(Headline)`
  font-weight: ${fontWeights.extra_bold};
  margin-top: 0.8rem;
`;

export const ImageContainer = styled.div`
  ${minWidth.lg`
    margin-left: unset;
    padding: 1rem;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center top; /* First value is from left and second is from top. You can use use number as well*/
    background-repeat: no-repeat;
    border-radius: 0.5rem;
  `}
`;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes, { any } from 'prop-types';
import { useRouter } from 'next/router';

import FocusTrap from 'focus-trap-react';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import { ThemeProvider } from 'styled-components';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import { Icon } from 'components/atoms';
import { ICONS } from 'constants/icons';
import { isMLargeToXLarge, isXSmallToMedium } from 'styles/media-queries';
import { useAppContext, ACTION_TYPES } from 'contexts/app-context';
import { useTranslation } from 'i18n/nexti18n';
import { getLinkProps } from 'utils/get-link-props';
import {
  eventTrack,
  NAVIGATION_BURGER_MENU_TOGGLE,
  MODULES_MULTI_CTA,
} from 'utils/google-tag-manager';
import getMultiCtaAction from 'utils/google-tag-manager/getMultiCtaAction';

import debounce from 'lodash/debounce';
import mParticle from '@mparticle/web-sdk';
import { SubNav } from './SubNav';
import * as Styled from './Navigation.styled';
/**
 * Global Navigation Component, creates the top global navigation of the page.
 * @param {Object} props.data The Navigation Component data to create the
 *    different links of the global navigation.
 * @param {string} props.collectionName The dynamic collection name.
 * @return {ReactComponent}
 */
const Navigation = ({
  data,
  collectionName,
  hideNavigationLinks,
  backgroundColor,
  theme,
  hasHero,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [appState, appDispatch] = useAppContext();
  const { locale, isNavigationOpen } = appState;
  const [navigationItem] = get(data, `${collectionName}.items`, []);
  const navigationItems = get(
    navigationItem,
    'navigationItemsCollection.items',
    [],
  );
  const ctaItems = get(navigationItem, 'ctasCollection.items', []);
  const overlayElement = useRef(null);
  const [resizing, setResizing] = useState(false);
  const [navHidden, setNavHidden] = useState(false);
  const [documentHeight, setDocumentHeight] = useState(0);
  const [contentMaxHeight, setContentMaxHeight] = useState(-Infinity);
  const [showNav, setShowNav] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const RESIZE_DEBOUNCE_DELAY = 250;

  const handleToggleOverlay = () => {
    appDispatch({
      type: ACTION_TYPES.NAVIGATION_TOGGLE,
      status: !isNavigationOpen,
    });
    appDispatch({
      type: ACTION_TYPES.MODAL_FORM_TOGGLE,
      status: false,
    });
    eventTrack(NAVIGATION_BURGER_MENU_TOGGLE, { isOpen: !isNavigationOpen });
  };

  const getToggleButtonAriaLabel = () =>
    isNavigationOpen
      ? t('navigationOverlay.close')
      : t('navigationOverlay.open');

  const closeOverlay = () =>
    appDispatch({
      type: ACTION_TYPES.NAVIGATION_TOGGLE,
      status: false,
    });

  const closeOnDesktop = () => {
    if (isMLargeToXLarge()) closeOverlay();
  };

  /**
   * Debounce implementation for navigation:
   * Implements a 100ms debounce
   * Sets the resizing property to true while the resize is happening
   * Sets the resizing property to false when the timer ends
   * Calls a closeOnDesktop function when the timer ends
   */
  let resizeTimer = null;

  const timeOutFunction = useCallback(() => {
    closeOnDesktop();
    setResizing(false);
    setDocumentHeight(window.innerHeight);
  }, []);

  const debouncedCloseOnDesktop = useCallback(() => {
    setResizing(true);
    clearTimeout(resizeTimer);

    resizeTimer = setTimeout(timeOutFunction, 100);
  }, []);

  // Change open state on resize.
  useEffect(() => {
    setDocumentHeight(window.innerHeight);
    window.addEventListener('resize', debouncedCloseOnDesktop);

    return () => {
      window.removeEventListener('resize', debouncedCloseOnDesktop);
    };
  }, []);

  // Disable, enable scroll on overlay open / close
  useEffect(() => {
    if (isNavigationOpen) {
      disableBodyScroll(overlayElement.current);
    } else {
      enableBodyScroll(overlayElement.current);
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [isNavigationOpen]);

  // Set aria-hidden for nav
  useEffect(() => {
    setNavHidden(!(isNavigationOpen || isMLargeToXLarge()));
  }, [isNavigationOpen, resizing]);

  useEffect(() => {
    const onResize = debounce(() => {
      setIsMobile(isXSmallToMedium());
    }, RESIZE_DEBOUNCE_DELAY);
    window.addEventListener('resize', onResize);
  }, []);

  // for hiding and showing navigation on scroll
  useEffect(() => {
    let prevScrollPosition = window.scrollY;
    window.addEventListener('scroll', () => {
      const currentScrollPositon = window.scrollY;
      // eslint-disable-next-line no-unused-expressions
      prevScrollPosition > currentScrollPositon || currentScrollPositon < 5
        ? setShowNav(true)
        : setShowNav(false);

      if (currentScrollPositon >= 5) {
        prevScrollPosition = currentScrollPositon;
      }
    });

    return () => {
      window.removeEventListener('scroll');
    };
  }, []);

  const createAdButton = ctaItems.map(
    ({ title, url, type, overrideFunctionality }) => {
      const { href, asLink } = getLinkProps(url);
      const ctaClickTrack = () => {
        const actionText = getMultiCtaAction({ overrideFunctionality, url });
        mParticle.logEvent('action', mParticle.EventType.Other, {
          action_text: actionText,
          action_location: MODULES_MULTI_CTA.navigationBar,
        });
      };
      return (
        <Styled.CtaContainer
          key={kebabCase(`${title}-${url}`)}
          className="create-button"
        >
          <Styled.CreateAdButton
            type={type}
            overrideFunctionality={overrideFunctionality}
            onClick={ctaClickTrack}
            href={href}
            asLink={asLink}
            isDarkTheme={theme === 'Dark'}
            isMobile
            mobileOverrideText={isMobile ? t('signUp') : t('createAnAd')}
          >
            {title}
          </Styled.CreateAdButton>
        </Styled.CtaContainer>
      );
    },
  );
  return (
    <ThemeProvider
      theme={{
        backgroundColor,
        open: isNavigationOpen,
        resizing,
        theme,
        showNav,
        isMobile,
        hasHero,
      }}
    >
      <FocusTrap
        active={isNavigationOpen}
        focusTrapOptions={{
          onDeactivate: closeOverlay, // This function is being triggered on ESC key press.
          allowOutsideClick: () => true,
        }}
      >
        <Styled.Header>
          <Styled.Container>
            <Styled.Content>
              <Styled.Logo
                href="/[locale]"
                asLink={`/${locale}`}
                onClick={() => {
                  mParticle.logEvent('action', mParticle.EventType.Other, {
                    action_text: 'logo click',
                    action_location: MODULES_MULTI_CTA.navigationBar,
                  });
                  closeOverlay();
                }}
                theme={theme}
                hasHero={hasHero}
                backgroundColor={backgroundColor}
              >
                <span className="sr-only">{t('spotifyAdvertising')}</span>
                <Icon name={ICONS.SPOTIFY_ADVERTISING} />
              </Styled.Logo>
              {isMobile && createAdButton}
              <Styled.Toggle
                onClick={handleToggleOverlay}
                aria-label={getToggleButtonAriaLabel()}
                aria-expanded={isNavigationOpen}
              >
                <Icon
                  className="nav-icon nav-icon-close"
                  name={ICONS.CLOSE_MARK}
                />
                <Icon
                  className="nav-icon nav-icon-hamburger"
                  name={ICONS.HAMBURGER}
                />
              </Styled.Toggle>
            </Styled.Content>
            {!hideNavigationLinks && (
              <Styled.NavContainer documentHeight={documentHeight}>
                <Styled.Nav aria-hidden={navHidden}>
                  <Styled.NavElement
                    ref={overlayElement}
                    scrollable
                    isOpen={isNavigationOpen}
                  >
                    {navigationItems.map(item => (
                      <SubNav
                        key={get(item, 'sys.id', '')}
                        item={item}
                        onClick={closeOverlay}
                        locale={locale}
                        onLayout={height => {
                          setContentMaxHeight(prevHeight =>
                            Math.max(prevHeight, height),
                          );
                        }}
                        listHeight={contentMaxHeight}
                        isMobile
                        className="sub-nav-menu-item"
                        isCurrentPath={
                          router.asPath.split('/')[2] === item.slug
                        }
                      />
                    ))}
                  </Styled.NavElement>
                  <Styled.NavElement>{createAdButton}</Styled.NavElement>
                </Styled.Nav>
              </Styled.NavContainer>
            )}
          </Styled.Container>

          <Styled.Backdrop onClick={closeOverlay} />
        </Styled.Header>
      </FocusTrap>
    </ThemeProvider>
  );
};

Navigation.propTypes = {
  /**
   * Navigation Component data to create the different links of the global
   * navigation.
   */
  data: PropTypes.objectOf(any),
  /**
   * The dynamic collection name.
   */
  collectionName: PropTypes.string.isRequired,
};

export default Navigation;

import styled from 'styled-components';

import { animations } from 'styles/variables';
import { maxWidth, minWidth } from 'styles/media-queries';
import { darkGray, spotifyGreen, white } from 'styles/colors';

// Constants
const DROPDOWN_MENU_WIDTH = 24; // value in rem

const itemContentDelay = `0.25s`;

// Styled components
export const DropdownMenu = styled.div`
  font-size: 1.4rem;
  line-height: 2.4rem;

  ${minWidth.md`
    font-size: 1.6rem;
  `}

  ${minWidth.lg`
    background-color: ${darkGray};
    color: ${white};
    border-radius: 1rem;
    position: absolute;
    right: calc(50% - ${DROPDOWN_MENU_WIDTH / 2}rem);
    transition: opacity ${animations.defaultTransition};
    width: ${DROPDOWN_MENU_WIDTH}rem;
  `}
`;

export const Container = styled.div`
  display: inline-block;
  position: relative;

  ${DropdownMenu} {
    opacity: 0;
    pointer-events: all;
    transition: opacity ${animations.defaultTransition};
    visibility: hidden;

    ${maxWidth.sm`
          padding: 1rem 2rem 5rem 5rem;
          position: fixed;
          opacity: 1;
          transition: right 1s ease-in-out;
          visibility: hidden;
          top: 0;
          right: -500px;
          height: 75vh;
          width: 100%;
      `}

    &.open {
      opacity: 1;
      transition: opacity ${animations.defaultTransition} ${itemContentDelay};
      visibility: visible;

      .sub-nav-menu-item-dropdown {
        opacity: 0;
        transform: translateY(-20px);
        animation: slideIn 400ms;
        animation-fill-mode: both;
      }

      .sub-nav-menu-item-dropdown:nth-child(1) {
        animation-delay: 100ms;
      }

      .sub-nav-menu-item-dropdown:nth-child(2) {
        animation-delay: 200ms;
      }

      .sub-nav-menu-item-dropdown:nth-child(3) {
        animation-delay: 300ms;
      }

      .sub-nav-menu-item-dropdown:nth-child(4) {
        animation-delay: 400ms;
      }

      .sub-nav-menu-item-dropdown:nth-child(5) {
        animation-delay: 500ms;
      }

      ${maxWidth.sm`
          padding: 1rem 2rem 5rem 5rem;
          position: fixed;
          visibility: visible;
          top: 0;
          right: 0;
          height: 75vh;
          z-index: 999;
          width: 100%;

          svg {
            opacity: 0;
            animation: slideIn 400ms;
            animation-fill-mode: both;
            width: 2rem;
            height: 2rem;
          }

          a {
            font-size: 2.4rem;
            line-height: 2.64rem;
          }

          a:hover {
            color: ${spotifyGreen};
          }

          .sub-nav-menu-item-dropdown:nth-child(1) {
            animation-delay: 300ms;
            margin-top: 2rem;
          }

          .sub-nav-menu-item-dropdown:nth-child(2) {
            animation-delay: 400ms;
          }

          .sub-nav-menu-item-dropdown:nth-child(3) {
            animation-delay: 500ms;
          }

          .sub-nav-menu-item-dropdown:nth-child(4) {
            animation-delay: 600ms;
          }

          .sub-nav-menu-item-dropdown:nth-child(5) {
            animation-delay: 700ms;
          }
      `}

      ${minWidth.lg`
        transition: opacity ${animations.defaultTransition};
      `}
    }
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateY(-50px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
  }
`;

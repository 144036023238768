import styled from 'styled-components';

import {
  Cta,
  DropdownMenu,
  DropdownMenuContainer,
  Icon,
  Tooltip,
  TooltipContainer,
} from 'components/atoms';
import { animations, fontWeights, navHeight } from 'styles/variables';
import { levels } from 'styles/z-index';
import { maxWidth, minWidth } from 'styles/media-queries';
import { container } from 'styles/grid';
import { gray20, solidWhite200 } from '@spotify-internal/encore-web';
import { black, darkGray, gray, spotifyGreen, white } from 'styles/colors';
import CtaComponent from '../../atoms/cta/Cta';
import { spacer16, spacer24, spacer48 } from '../../../styles/spacers';

/* Transitions, Constants */
const opacityTransition = 'opacity 0.7s ease-in-out';
const opacityTransitionDelay = '0.8s';

const transformTransition = 'transform 1s ease-in-out';
const transformTransitionDelay = '0.5s';

const itemContentDelay = `0.3s`;
const expandCollapseTransition = '0.5s ease-in-out';

const SUB_NAVIGATION_TOP_OFFSET = 1;

/* Dropdown menu children list elements height constants for mobile */
const childrenHeight = 52;
const mdChildrenHeight = 88;

/* Styled Components */
export const Header = styled.header`
  align-items: center;
  background-color: ${props =>
    props.theme.backgroundColor ? props.theme.backgroundColor : black};
  display: flex;
  position: fixed;
  width: 100%;
  z-index: ${levels.persistent};
  -webkit-transition: opacity 200ms, visibility 300ms;
  transition: opacity 200ms, visibility 300ms;

  ${({ theme }) =>
    !theme.showNav &&
    `
      opacity: 0;
      visibility: hidden
    `}

  ${({ theme }) =>
    theme.showNav &&
    `
      opacity: 1;
      visibility: visible;
    `}
`;

export const Container = styled.div`
  ${container}

  background-color:${props =>
    props.theme.backgroundColor ? props.theme.backgroundColor : black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: ${levels.highlight};
`;

export const Content = styled.div`
  background-color: ${props =>
    props.theme.backgroundColor ? props.theme.backgroundColor : black};
  display: flex;
  height: ${`${navHeight.smToLg}rem`};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: ${levels.highlight};
  span:last-of-type {
    margin-left: auto;
  }

  ${maxWidth.sm`
    height: ${navHeight.mlUp}rem;
      .create-button {
        width: max-content;
        transform: scale(0.9);
        margin-right: -.4rem;
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  `}

  ${maxWidth.md`
    height: ${navHeight.mlUp}rem;
  `}

  ${minWidth.lg`
    width: auto;
  `}
`;

export const Logo = styled(Cta).attrs({ type: 'textlink' })`
  display: block;

  path {
    fill: ${props =>
      props.theme === 'Dark' || !props.hasHero ? spotifyGreen : black};
  }

  /* ReactSVG adds extra div wrapper */
  div,
  svg {
    height: 3.2rem;

    ${minWidth.md`
      height: 3.2rem;
    `}
  }
`;

export const Toggle = styled.button`
  height: 4.4rem;
  position: relative;
  width: 4.4rem;

  /* IE Icon position fix */
  div {
    align-items: center;
    display: flex;
    height: 4.4rem;
    justify-content: center;
    width: 4.4rem;
  }

  svg {
    height: 2.4rem;
    width: 2.4rem;
  }

  .nav-icon {
    position: absolute;
    top: 0;
  }

  .nav-icon-close {
    opacity: 0;
  }

  .nav-icon-hamburger {
    opacity: 1;
    transition: ${opacityTransition};
    transition-delay: ${opacityTransitionDelay};
    color: ${props =>
      props.theme.theme === 'Dark' || !props.theme.hasHero
        ? solidWhite200
        : black};

    div {
      svg {
        path {
          fill: ${props =>
            props.theme.theme === 'Dark' || !props.theme.hasHero
              ? solidWhite200
              : black};
        }
      }
    }
  }

  ${props =>
    props.theme.open &&
    `
    .nav-icon-close {
      opacity: 1;
      transition: ${opacityTransition};
      transition-delay: ${opacityTransitionDelay};
      color: ${
        props.theme.theme === 'Dark' || !props.theme.hasHero
          ? solidWhite200
          : black
      };

       div {
        svg {
          path {
            fill: ${
              props.theme.theme === 'Dark' || !props.theme.hasHero
                ? white
                : black
            };
          }
        }
      }
    }

    .nav-icon-hamburger {
      opacity: 0;
      transition: none;

     div {
       svg {
        path {
         fill: ${props.theme.theme === 'Dark' ? white : black};
        }
      }
     }
    }
  `}

  ${minWidth.lg`
    display: none;
  `}
`;

export const NavContainer = styled.div`
  ${container}

  background-color: ${props =>
    props.theme.backgroundColor ? props.theme.backgroundColor : black};
  font-size: 1.4rem;
  height: ${`calc(100vh - ${navHeight.smToLg}rem)`};
  ${props =>
    props.documentHeight
      ? `height: calc(${props.documentHeight}px - ${navHeight.smToLg}rem);`
      : ''};
  left: 0;
  padding-bottom: 4rem;
  padding-top: 3.15rem;
  position: absolute;
  top: ${`${navHeight.smToLg}rem`};
  transform: ${`translateY(calc(-100% - ${navHeight.smToLg}rem))`};
  transition-delay: ${transformTransitionDelay};
  transition: ${transformTransition};
  width: 100%;
  z-index: ${levels.base};

  ${maxWidth.sm`
        padding: ${spacer48} ${spacer24};
        z-index: ${levels.persistent};
   `}

  ${props =>
    props.theme.open &&
    `
      transform: translateY(0);
      transition-delay: 0s;
  `}

  ${props => props.theme.resizing && `transition: none;`}

  ${minWidth.md`
    height: ${`calc(100vh - ${navHeight.mlUp}rem)`};
    padding-top: 3.45rem;
    top: ${navHeight.mlUp}rem;
    transform: translateY(calc(-100% - ${navHeight.mlUp}rem));

    ${props =>
      props.theme.open &&
      `
      display: inherit;
      transform: translateY(0);
    `}
  `}

  ${minWidth.lg`
    height: ${navHeight.mlUp}rem;
    padding: 0;
    position: relative;
    top: 0;
    transform: translateY(0);
    transition: none;
    left: 2rem;
  `}
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  height: 100%;
  opacity: 0;
  transition: ${opacityTransition};

  ${props =>
    props.theme.open &&
    `
      opacity: 1;
      transition-delay: ${opacityTransitionDelay};
    `}

  ${minWidth.lg`
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    opacity: 1;
    transition: none;
  `}
`;

export const NavElement = styled.div`
  display: flex;
  flex-direction: column;

  ${props =>
    props.scrollable &&
    `
    flex: 1;
    overflow: hidden;
  `}
  ${maxWidth.sm`
  ${props =>
    props.isOpen &&
    `
      padding-top: 2rem;
      .sub-nav-menu-item {
        opacity: 0;
        transform: translateY(-50px);
        animation: slideIn 500ms;
        animation-fill-mode: forwards;
      }

      .sub-nav-menu-item:nth-child(1) {

        animation-delay: 1000ms;
      }

      .sub-nav-menu-item:nth-child(2) {
        animation-delay: 1100ms;
      }

      .sub-nav-menu-item:nth-child(3) {
        animation-delay: 1200ms;
      }

       .sub-nav-menu-item:nth-child(4) {
        animation-delay: 1300ms;
      }

      button {
           opacity: 0;
        transform: translateY(-50px);
        animation: slideIn 500ms;
        animation-fill-mode: forwards;
        animation-delay: 1400ms;
      }
    `}
  `}

  ${maxWidth.sm`
    span:last-of-type{
      margin-top: ${spacer16};
    }
  `}


  ${minWidth.md`
    flex-direction: column;
    span:last-of-type {
    margin-left: 0;
  }
  `}

  ${minWidth.lg`
    align-items: center;
    flex-direction: row;
    height: 100%;
    justify-content: flex-start;
    overflow: inherit;
    span:last-of-type {
    margin-right: 2rem;
    margin-left: auto;
  }
  `}

  @keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const NavTextLinkContainer = styled.span`
  margin-bottom: 1.6rem;
  max-width: 40.8rem;
  max-height: ${props =>
    props.subNavFocused ? `${props.listHeight + childrenHeight}px` : '2.4rem'};
  transition: max-height ${expandCollapseTransition} ${itemContentDelay};

  ${maxWidth.sm`
     ${props =>
       props.level !== 'lower' &&
       `
       margin-bottom: ${spacer48};

        a {
          font-size: 32px;
          line-height: 35.2.px;
        }
   `}
`}

  &.lower {
    display: flex;
    ${maxWidth.sm`
      max-width: 100%;
      flex-direction: row;
      justify-content: space-between;
      margin: unset;
      margin-right: 0.5rem;
      margin-bottom: 0;
  `}

    ${minWidth.md`
      display: ${props => (props.isBackButton ? 'inline-block' : 'flex')};
      max-width: 100%;
      flex-direction: row;
      justify-content: space-between;
      margin-right: -0.5rem;
      margin-bottom: 0;
  `}
  }

  ${minWidth.md`
    margin-bottom: 3.2rem;
    max-height: ${props =>
      props.subNavFocused
        ? `${props.listHeight + mdChildrenHeight}px`
        : '4.8rem'};
  `}

  ${minWidth.lg`
    display: flex;
    height: 100%;
    max-height: 100%;
    margin-bottom: 0;
    margin-right: 24px;
    position: relative;

    &:hover {
      color: ${spotifyGreen};
    }
  `}
`;

export const NavTextLink = styled(Cta).attrs({
  type: 'textLink',
})`
  color: ${props => {
    // eslint-disable-next-line no-nested-ternary
    return props.isCurrentPath
      ? spotifyGreen
      : props.theme.theme === 'Dark' ||
        !props.theme.hasHero ||
        !props.theme.backgroundColor
      ? white
      : black;
  }};
  font-size: 2rem;
  font-weight: ${fontWeights.extra_bold};
  pointer-events: all;
  text-decoration: none;
  transition: transform 0.25s;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.5rem;
  }

  &.lower {
    color: ${props => {
      // eslint-disable-next-line no-nested-ternary
      return props.isCurrentPath
        ? white
        : props.theme.theme === 'Dark'
        ? white
        : black;
    }};
    text-decoration: ${props => props.isCurrentPath && 'underline'};
    text-underline-offset: 0.5rem;
    font-weight: ${fontWeights.normal_p};
    margin-right: 1.5rem;
    line-height: 2rem;
    font-size: 1.6rem;

    ${maxWidth.sm`
    flex: auto;
    margin-top: 0rem;
    width: max-content;
  `}

    ${minWidth.md`
    flex: auto;
    letter-spacing: .025rem;
    width: max-content;
  `}

    ${minWidth.lg`
    align-items: center;
    display: flex;
    width-max-content;

    &:hover {
      color: ${gray};
      text-decoration: 'underline';
    }
  `}
  }

  ${maxWidth.sm`
    font-size: 2.5rem;

    button {
      font-size: 2rem;
    }
  `}

  ${minWidth.md`
    font-size: 4.4rem;
    letter-spacing: -0.15rem;
    line-height: 4.8rem;
  `}

  ${minWidth.lg`
    align-items: center;
    display: flex;
    font-size: 1.6rem;
    font-weight: ${fontWeights.extra_bold};
    height: 100%;
    letter-spacing: normal;
    line-height: 3.2rem;

    &:hover {
      color: inherit;
    }
  `}
`;

export const NavTextLinkDrop = styled(NavTextLink).attrs({
  tag: 'button',
})`
  &.lower {
    ${maxWidth.sm`
      font-size: 1.6rem;
    `}
  }
  &:hover {
    color: ${spotifyGreen};
  }
`;

export const SubNavLink = styled(NavTextLink)`
  font-size: 1.8rem;
  color: ${white};

  ${maxWidth.sm`
    font-size: 3rem;
    letter-spacing: -0.1rem;

  `}

  ${minWidth.md`
    letter-spacing: -0.1rem;
    line-height: 3.2rem;
  `}

  &.lower {
    ${minWidth.md`
    font-size: 1.6rem;
  `}
  }

  ${minWidth.lg`
    display: inline-block;
    font-size: 1.6rem;
    color: ${white};
    font-weight: ${fontWeights.bold};
    letter-spacing: normal;
    line-height: 2.4rem;
  `}
`;

export const Caret = styled(Icon)`
  display: inline-block;
  font-size: 0;
  transition: transform ${animations.defaultTransition};

  div {
    align-items: center;
    display: flex;
    height: 4.4rem;
    justify-content: center;
    width: 4.4rem;
  }

  .nav-caret {
    position: absolute;
  }

  div,
  svg {
    width: inherit;

    path {
      fill: ${props =>
        props.theme.theme === 'Dark' || !props.theme.hasHero ? white : black};
    }
  }

  ${maxWidth.sm`
    margin-left: 0.4rem;
    height: 1.15rem;
    margin-top: 0.45rem;
    z-index: 20;
     margin-bottom: 3rem;

     svg {
      width: 2rem;
      height: 2rem;
     }
  `}

  ${maxWidth.md`
    margin-bottom: 3rem;
  `}

  ${minWidth.lg`
    display: block;
    margin-left: 0.8rem;
  `}
`;

export const SubNav = styled(DropdownMenu)`
  margin-bottom: 0.8rem;
  margin-left: 0.8rem;
  margin-top: 1.6rem;
  transition: ${animations.defaultTransition};
  background-color: ${props => props.theme.backgroundColor || black};

  ${maxWidth.sm`
      position: fixed;
      top: 0;
      right: 0;
      margin-left: -1rem;
      transition: .25s ease;

      .subnav-caret {
        transform: rotate(90deg) translate(1rem, -1rem);
      }
  `}

  ${maxWidth.md`
    margin-left: 1.6rem;
    margin-top: 3.2rem;
    top: calc(100% - ${SUB_NAVIGATION_TOP_OFFSET}rem);

    li {
      a {
        color: ${props => (props.theme.theme === 'Dark' ? white : black)};
      }
    }

    ${props =>
      props.isMobile &&
      `
      background-color: ${props.theme.backgroundColor || black};
      z-index: 100000;
      position: relative;
      bottom: 0;
      padding-top: 10rem;;
      padding-left: 8rem;
      color: ${props.theme.theme === 'Dark' ? white : black};
      height: 100vh;
      width: 100vw;
      margin-left: -1rem;
      transition: .25s ease;
      top: calc(100% - ${SUB_NAVIGATION_TOP_OFFSET}rem);
      `};
  `}

  ${minWidth.lg`
    background-color: ${darkGray};
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 0;
    top: calc(100% - ${SUB_NAVIGATION_TOP_OFFSET}rem);
    transform: translateY(-1rem);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

    .subnav-caret {
      display: none;
    }
  `};
`;

export const LowerSubNav = styled(DropdownMenu)`
  margin-bottom: 0.8rem;
  margin-left: 0.8rem;
  margin-top: 1.6rem;

  ${maxWidth.sm`
    position: absolute;
    padding: 2rem;
    background-color: ${solidWhite200};
    width: 24rem;
    transform: translateY(-3rem);
  `}

  ${minWidth.md`
    position: absolute;
    padding: 2rem;
    background-color: ${solidWhite200};
    width: 24rem;
  `}

  ${minWidth.lg`
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 0;
    top: calc(100% - ${SUB_NAVIGATION_TOP_OFFSET}rem);
    transform: translateY(3rem);
    transition: transform ${animations.defaultTransition}, opacity ${animations.defaultTransition};
  `};
`;

export const SubNavContainer = styled(DropdownMenuContainer)`
  pointer-events: ${({ subNavFocused }) => `${subNavFocused ? 'all' : 'none'}`};

  ${maxWidth.sm`
    ${SubNav} {
    transition: .5s ease-in-out;
    }

    .mobile-nav-caret {
        svg {
          transform: rotate(-90deg) translateX(-0.35rem);
        }
    }
  `}


  ${minWidth.md`
    ${SubNav} {
    transition: .25s ease-in-out;
      transform: ${({ subNavFocused }) =>
        `${
          subNavFocused
            ? `translate(-1rem, -50rem)`
            : 'translate(10rem, -50rem)'
        }`};
    }

    ${Caret} {
    transform: ${({ subNavFocused }) =>
      `${subNavFocused ? 'rotate(90deg)' : 'rotate(-90deg)'}`};
  }
  `}

  ${minWidth.lg`
    ${SubNav} {
      transform: ${({ subNavFocused }) =>
        `${subNavFocused ? 'translateY(0)' : 'translateY(-1rem)'}`};
    }


  ${Caret} {
    transform: ${({ subNavFocused }) =>
      `${subNavFocused ? 'rotate(180deg)' : 'unset'}`}
  }
  `}
`;

export const LowerSubNavContainer = styled(DropdownMenuContainer)`
  pointer-events: ${({ subNavFocused }) => `${subNavFocused ? 'all' : 'none'}`};

  ${Caret} {
    transform: ${({ subNavFocused }) =>
      `${subNavFocused ? 'rotate(180deg)' : 'unset'}`}
  }


  ${maxWidth.sm`
     ${SubNav} {
      transform: ${({ subNavFocused }) =>
        `${subNavFocused ? 'translateY(0)' : 'translateY(-1rem)'}`};
    }

    ${Caret} {
    transform: ${({ subNavFocused }) =>
      `${subNavFocused ? 'rotate(180deg)' : 'unset'}`}
  }
  `}


  ${minWidth.md`
    ${SubNav} {
      transform: ${({ subNavFocused }) =>
        `${subNavFocused ? 'translateY(0)' : 'translateY(-1rem)'}`};
    }
  `}

  ${minWidth.lg`
    ${SubNav} {
      transform: ${({ subNavFocused }) =>
        `${subNavFocused ? 'translateY(0)' : 'translateY(-1rem)'}`};
    }
  `}
`;

export const SubNavItem = styled.li`
  padding: 1.5rem 2.4rem 1rem 2.4rem;
  border-radius: 8px;

  ${maxWidth.sm`
     padding: 0rem;
      font-size: 24px;
      margin: 0 0 2.4rem 0;
  `}

  a {
    vertical-align: center;
  }

  a:hover {
    text-decoration: unset;
  }

  &:hover {
    background-color: ${gray20};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const LowerSubNavItem = styled.li`
  margin-bottom: 0.8rem;

  ${maxWidth.sm`
    a {
      font-size: 1.6rem;
      color: ${black};
      font-weight: lighter;
    }

  `}

  ${minWidth.md`
     a {
      font-size: 1.6rem;
      color: ${black};
      font-weight: lighter;
    }
  `}

  ${minWidth.lg`
    margin-bottom: 0.8rem;
  `}

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CtaContainer = styled.span`
  a,
  button {
    height: 4.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  ${minWidth.md`

   ${({ theme }) =>
     theme.isMobile &&
     `
    margin-right: 0;
  `}

  `}

  ${minWidth.lg`
    margin-right: 2rem;
    margin-top: 0;
    max-width: 100%;

    &:last-child {
      margin-right: 0;
    }

    a {
      width: auto;
    }
  `}
`;

export const CreateAdButton = styled(CtaComponent)`
  ${maxWidth.sm`
     padding: 1rem;
     display: flex;
     align-items: center;
     justify-content: center;
  `}

  ${minWidth.lg`
    padding: 1rem 2rem 1rem 2rem;
    font-weight: ${fontWeights.extra_bold}
  `}
`;

// Use for Nav CTA Tooltip
export const NavTooltipContainer = styled(TooltipContainer)`
  display: flex;
  flex-direction: column-reverse;

  ${minWidth.lg`
    display: inline-block;
  `}
`;

// Use for Nav CTA Tooltip
export const NavTooltip = styled(Tooltip)`
  margin-bottom: 2.4rem;

  ${minWidth.md`
    margin-bottom: 3.2rem;
  `}

  ${minWidth.lg`
    margin-bottom: 0;
  `}
`;
export const Backdrop = styled.div`
  background-color: ${black};
  bottom: 0;
  cursor: pointer;
  height: 100vh;
  left: 0;
  opacity: ${props => (props.theme.open ? 1 : 0)};
  pointer-events: ${props => (props.theme.open ? 'all' : 'none')};
  position: absolute;
  right: 0;
  top: 0;
  transition: ${opacityTransition};
  width: 100vw;

  ${minWidth.lg`
    display: none;
    transition: none;
  `}
`;

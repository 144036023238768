import React from 'react';
import PropTypes, { any } from 'prop-types';
import { get, kebabCase } from 'lodash';

import { ThemeProvider } from 'styled-components';
import { Headline } from 'components/atoms';
import { getLinkProps } from 'utils/get-link-props';
import * as Styled from './CtaBanner.styled';

/**
 * CTA Banner component
 * @param {String} title title of cta banner
 * @param {String} description description of cta banner
 * @param {String} backgroundColor optional background color
 * @param {String} textColor optional text color
 * @param {boolean} centered centered style
 * @param {boolean} fullWidth full width style
 * @param {boolean} isArticleMargin article page style
 * @param {Array} ctas list of CTAs
 * @returns {ReactElement}
 */
const CtaBanner = ({
  title = '',
  description = '',
  backgroundColor = '',
  textColor = '',
  centered = false,
  fullWidth = false,
  isArticleMargin = false,
  ctas = [],
}) => {
  const ctaItems = get(ctas, 'items', []);
  return (
    <ThemeProvider
      theme={{
        backgroundColor,
        textColor,
        centered,
        fullWidth,
        isArticleMargin,
      }}
    >
      <Styled.CtaBanner className="cta-banner">
        <Styled.CtaContent>
          {title && (
            <Headline styling="h2" tag="h2">
              <strong>{title}</strong>
            </Headline>
          )}
          {description && (
            <Styled.CtaDescription styling="p1">
              {description}
            </Styled.CtaDescription>
          )}
          {ctaItems && (
            <Styled.CtasContainer>
              {ctaItems.map(cta => (
                <Styled.CtaButton
                  {...getLinkProps(cta.url)}
                  type="secondary"
                  key={kebabCase(`${cta.title}-${cta.url}`)}
                >
                  {cta.title}
                </Styled.CtaButton>
              ))}
            </Styled.CtasContainer>
          )}
        </Styled.CtaContent>
      </Styled.CtaBanner>
    </ThemeProvider>
  );
};

CtaBanner.propTypes = {
  /**
   * title of cta banner
   */
  title: PropTypes.string,
  /**
   * description of cta banner
   */
  description: PropTypes.string,
  /**
   * optional background color
   */
  backgroundColor: PropTypes.string,
  /**
   * optional text color
   */
  textColor: PropTypes.string,
  /**
   * centered styling
   */
  centered: PropTypes.bool,
  /**
   * full width styling
   */
  fullWidth: PropTypes.bool,
  /**
   * article page styling
   */
  isArticleMargin: PropTypes.bool,
  /**
   * List of CTAs
   */
  ctas: PropTypes.arrayOf(PropTypes.objectOf(any)),
};

export default CtaBanner;

// @ts-nocheck
// this is not preferred when using TS but as this is just an example, we are
// using it to avoid need to convert styles/media-queries to TS
import styled from 'styled-components';

import { Eyebrow, Headline, ArrowCta as CtaComponent } from 'components/atoms';
import { getThemeColor } from 'utils/get-theme-color';
import { columnsGutter, container } from 'styles/grid';
import { minWidth } from 'styles/media-queries';
import { Markdown } from 'components/molecules';

import { multiple } from 'components/atoms/arrow-cta/ArrowCta.styled';

// Styled components
export const VideoFeatureCard = styled.section`
  background-color: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => getThemeColor(theme.themeKey)};
  padding: 4rem 0;

  ${minWidth.lg`
    padding: 7.2rem 0;


    ${({ theme }) =>
      theme.hasVerticalVideo &&
      `
      padding: unset;
    `}
  `}
`;

export const Container = styled.div`
  ${container}

  ${minWidth.lg`
    ${columnsGutter}

    display: grid;
    grid-template-columns: repeat(12, 1fr);

    ${({ theme }) =>
      (theme.hasVideo || theme.hasVerticalVideo) &&
      `
      align-items: center;
    `}
  `}
`;

export const VideoContainer = styled.div`
  display: block;
  margin-bottom: 4rem;
  overflow: hidden;
  position: relative;

  ${minWidth.lg`
    grid-column: 2 / span 6;
    grid-row-start: 1;
    margin-bottom: 0;

     ${({ theme }) =>
       theme.hasVerticalVideo &&
       `
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-column: 1 / span 6;
    `}

    ${({ theme }) =>
      theme.videoRight &&
      `
      grid-column: 6 / span 6;
    `}
  `}
`;

export const AudioToggleContainer = styled.div`
  display: flex;
  justify-content: center;

  ${minWidth.lg`
    grid-column: 1 / span 1;
    grid-row-start: 1;
    align-self: start;

    ${({ theme }) =>
      theme.videoRight &&
      `
        grid-column: 12 / span 1;
    `}
  `}
`;

export const Content = styled.div`
  margin-bottom: 4.3rem;
  ${minWidth.lg`
    ${columnsGutter}

    display: grid;
    grid-column: span 12;
    grid-row-start: 1;
    grid-template-columns: repeat(12, 1fr);

    margin-bottom: 0;

     ${({ theme }) =>
       theme.hasVerticalVideo &&
       `
      display: flex;
      flex-direction: column;
      justify-content: center;
      grid-column: 6 / span 6;
    `}

    ${({ theme }) =>
      theme.hasVideo &&
      `
      display: flex;
      flex-direction: column;
      grid-column: 8 / span 5;
    `}

    ${({ theme }) =>
      theme.videoRight &&
      `
      grid-column: 1 / span 5;
      grid-row: 1;
    `}

    ${({ theme }) =>
      theme.videoRight &&
      theme.hasVerticalVideo &&
      `
      grid-column: 1 / span 6;
      grid-row: 1;
    `}
  `}
`;

export const HeadlineContainer = styled.div`
  margin-bottom: 1.6rem;

  ${minWidth.lg`
    grid-column: span 5;
    margin-bottom: 0;

    ${({ theme }) =>
      (theme.hasVideo || theme.hasVerticalVideo) &&
      `
      margin-bottom: 2.4rem;
    `}
  `}
`;

export const ColumnList = styled(props => <Markdown {...props} />)`
  margin: 2.4rem 0 5rem 0;
`;

export const TitleEyebrow = styled(Eyebrow)`
  margin-bottom: 0.8rem;

  ${minWidth.lg`
  margin-bottom: 2.4rem;
`}
`;

export const Title = styled(Headline)`
  color: ${({ theme }) => theme.titleColor || getThemeColor(theme.themeKey)};
`;

export const DescriptionContainer = styled.div`
  ${minWidth.lg`
  grid-column: 7 / span 5;
`}

  li {
    font-weight: bold;
  }
`;

export const CtasContainer = styled.div`
  margin-top: 3.2rem;
`;

export const Cta = styled(CtaComponent)`
  ${multiple};
`;
